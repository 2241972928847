import styled from 'styled-components';
import { useEffect, useState } from 'react';
const Test = () => {
  const [splashScreenVisible, setSplashScreenVisible] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setSplashScreenVisible(false);
    }, 5000);
  }, []);
  return (
    <TestContainer className="App">
      {splashScreenVisible ? (
        <div className="splash-screen">
          <div className="left"></div>
          <div className="splash-screen-line" />
          <div className="right"></div>
        </div>
      ) : (
        <div className="main-content">
          <h1>Main Content</h1>
        </div>
      )}
    </TestContainer>
  );
};
const TestContainer = styled.div`
  height: 100vh;
  
  display: flex;
  align-items: center;
  justify-content: center;
   background-color: transparent !important;
  .splash-screen {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent !important;
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .splash-screen-line {
    height: 0;
    width: 1px;
    background-color: white;
    animation: grow 3s ease-out forwards;
    position: absolute;
    left: 50%;
    right: 50%;
    z-index: 100000;
  }
  .left {
    width: 50%;
    height: 100vh;
    background-color: black;
    position: absolute;
    left: 0;
    animation: try 1s ease-out forwards;
    animation-delay: 3s;
  }
  .right {
    width: 50%;
    height: 100vh;
    background-color: black;
    position: absolute;
    right: 0;
    animation: righttry 1s ease-out forwards;
    animation-delay: 3s;
  }
  @keyframes grow {
    0% {
      height: 0vh;
      opacity: 1;
    }
    50% {
      height: 50vh;
      opacity: 1;
    }
    80% {
      height: 50vh;
      opacity: 1;
    }
    100% {
      height: 100vh;
      opacity: 0;
    }
  }
  @keyframes try {
    0% {
      left: 0%;
    }
    50% {
      left: -50%;
    }
    100% {
      left: -100%;
    }
  }
  @keyframes righttry {
    0% {
      right: 0%;
    }
    50% {
      right: -50%;
    }
    100% {
      right: -100%;
    }
  }
  .main-content {
    background-color: transparent !important;
    height: 100vh;
    width: 0vw;
    display: flex;
    align-items: center;
    justify-content: center;
    /* animation: fade-in 3s ease-in; */
  }
`;
export default Test;