import styled from 'styled-components';

// Assets
import portfolio_img_2 from '../assets/images/2.jpg';



const ImageComponent = ({ImgSrc}) => {

  if (ImgSrc == null)
  {
    ImgSrc = portfolio_img_2;
  }

    return (
        <Wrapper >
            <img alt='' src={ImgSrc} id='PersonalImg'></img>
        </Wrapper>
    );
};

const Wrapper = styled.div`
@media (max-width:1200px) {
  display: none;
}

width: 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
flex: 1 1 0;

#PersonalImg {
  width: 100%;
  height: 100%;
}

`


export default ImageComponent;