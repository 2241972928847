import styled from "styled-components";

import ImageComponent from "../components/ImageComponent";
import imagemodal from '../assets/images/3_1.jpg'

import EmailPopUp from "../components/emailSentPopUp";

import React, { useRef, useState } from 'react';
import { gsap } from "gsap/src";



const Contact = () => {

  const [visiblePopUpTrue, setVisiblePopUpTrue] = useState(false);
  const [visiblePopUpFalse, setVisiblePopUpFalse] = useState(false);

  const TogglePopUpTrue = () => {
    setVisiblePopUpTrue((prev) => !prev);
  };
  const TogglePopUpFalse = () => {
    setVisiblePopUpFalse((prev) => !prev);
  };

  // gsap.registerPlugin(MorphSVGPlugin);
  
  
    const email = useRef()
    const firstName = useRef()
    const lastName = useRef()
    const subject = useRef()
    const message = useRef()

  const send_email = e => {
    if (email === '' || firstName === '' || lastName === '' || subject === '' || message === '') {
      return
    }
    e.preventDefault();    
    console.log("sending email")
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "data": {
        "firstName": firstName.current.value,
        "lastName": lastName.current.value,
        "subject": subject.current.value,
        "email": email.current.value,
        "message": message.current.value
      }
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("/api/send_email", requestOptions)
      .then(response => {
        if(response.status !== 200)
        {
          TogglePopUpFalse();
        }
        else{
          TogglePopUpTrue();
        }
      })
      .catch(error => console.log('error', error));
    document.querySelectorAll('.button').forEach(element => {

      let path = element.querySelector('.btn-layer path'),
        tl = gsap.timeline();

        e.preventDefault()
        // document.getElementById("emailform").submit();
        if (email === '' || firstName === '' || lastName === '' || subject === '' || message === '') {
          return
        }
        if (element.classList.contains('active')) {
          return
        }
        element.classList.add('active')
        tl.to(path, {
          morphSVG: 'M136,77.5h-1H4.8H4c-2.2,0-4-1.8-4-4v-47c0-2.2,1.8-4,4-4c0,0,0.6,0,0.9,0C44,22.5,66,10,66,10  s3,12.5,69.1,12.5c0.2,0,0.9,0,0.9,0c2.2,0,4,1.8,4,4v47C140,75.7,138.2,77.5,136,77.5z',
          duration: .3,
          delay: .3
        }).to(path, {
          morphSVG: 'M136,77.5c0,0-11.7,0-12,0c-90,0-94.2,0-94.2,0s-10.8,0-25.1,0c-0.2,0-0.8,0-0.8,0c-2.2,0-4-1.8-4-4v-47  c0-2.2,1.8-4,4-4c0,0,0.6,0,0.9,0c39.1,0,61.1,0,61.1,0s3,0,69.1,0c0.2,0,0.9,0,0.9,0c2.2,0,4,1.8,4,4v47  C140,75.7,138.2,77.5,136,77.5z',
          duration: 1.7,
          ease: 'elastic.out(1, .15)',
          onComplete() {
            element.classList.remove('active')
          }
        })
    })


    email.current.value = ''
    firstName.current.value = ''
    lastName.current.value = ''
    subject.current.value = ''
    message.current.value = ''


  }


  return (
    <ContactContainer>
      <EmailPopUp open={visiblePopUpTrue} onClose={() => setVisiblePopUpTrue(false)} email_send_success={true}></EmailPopUp>
      <EmailPopUp open={visiblePopUpFalse} onClose={() => setVisiblePopUpFalse(false)} email_send_success={false}></EmailPopUp>

      <div className="ContactContent">
        <ImageWrapper>
          <ImageComponent ImgSrc={imagemodal} />
        </ImageWrapper>
        <ContactItemsWrapper>
          <div className="ContactItemsWrapper_content" id="touch">
            <div className="ContactItemsWrapper_title" id="touch">
              <div className="ContactItemsWrapper_title_text">
                Get in touch
              </div>
              <div className="ContactItemsWrapper_title_line">
                <div className="line"></div>
              </div>
            </div>

            <form className="ContactItemsWrapper_form" id="emailform" onSubmit={send_email}>
              <div className="ContactItemsWrapper_formField field_res" id="FNbar">
                <input id="Fname" className="input-text js-input field_res" type="text" name="user_name" required placeholder="First Name" ref={firstName} />
              </div>

              <div className="ContactItemsWrapper_formField field_res" id="LNbar">
                <input id="Lname" className="input-text js-input field_res" type="text" name="user_lastname" required placeholder="Last Name" ref={lastName} />
              </div>

              <div className="ContactItemsWrapper_formField field_res" id="Subjectbar">
                <input id="subject" className="input-text js-input field_res" type="text" name="user_topic" required placeholder="Topic" ref={subject} />
              </div>

              <div className="ContactItemsWrapper_formField field_res">
                <input id="email" className="input-text js-input field_res" type="email" name="user_email" required placeholder="E-mail" ref={email} />
              </div>

              <div className="ContactItemsWrapper_formField msg_body">
                <textarea id="message" className="input-text msg_body js-input" type="textarea" name="message" required placeholder="Message" ref={message} />
              </div>

              <div className="ContactItemsWrapper_formField" id="send">
                <button className="button" type="submit" value="submit">
                  <svg className="btn-layer">
                    <path d="M136,77.5c0,0-11.7,0-12,0c-90,0-94.2,0-94.2,0s-10.8,0-25.1,0c-0.2,0-0.8,0-0.8,0c-2.2,0-4-1.8-4-4v-47  c0-2.2,1.8-4,4-4c0,0,0.6,0,0.9,0c39.1,0,61.1,0,61.1,0s3,0,69.1,0c0.2,0,0.9,0,0.9,0c2.2,0,4,1.8,4,4v47  C140,75.7,138.2,77.5,136,77.5z" />
                  </svg>
                  <svg className="plane">
                    <use xlinkHref="#plane" />
                  </svg>
                  <ul>
                    <li>Send</li>
                    <li>Done</li>
                  </ul>
                </button>
                <svg xmlns="http://www.w3.org/2000/svg" style={{ display: "none" }}>
                  <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 100" id="btn-layer" preserveAspectRatio="none">
                    <path d="M133,77.5H7c-3.9,0-7-3.1-7-7v-41c0-3.9,3.1-7,7-7h126c3.9,0,7,3.1,7,7v41C140,74.4,136.9,77.5,133,77.5z" />
                  </symbol>
                  <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 26" id="plane" preserveAspectRatio="none">
                    <path d="M5.25,15.24,18.42,3.88,7.82,17l0,4.28a.77.77,0,0,0,1.36.49l3-3.68,5.65,2.25a.76.76,0,0,0,1-.58L22,.89A.77.77,0,0,0,20.85.1L.38,11.88a.76.76,0,0,0,.09,1.36Z" />
                  </symbol>
                </svg>

              </div>
            </form>

          </div>
        </ContactItemsWrapper>
      </div>
    </ContactContainer>
  );
}

const ContactContainer = styled.div`

width: 100%;
height: 100%;

/* background-color: rgba(250, 249, 246, 1); */
background-color: white;
overflow: hidden;

color: rgba(0,0,0,0.9);
font-family: 'Montserrat', sans-serif;

.ContactContent {
  width: 100%;
  height: 100%;
  
  padding-top: 0.3%;
  padding-bottom: 0.3%;
  padding-left: 5%;
  /* padding-right: 0.2%; */

  display: flex;


  @media (max-width: 900px) {
    padding-top: 0.5%;
    padding-bottom: 0.1%;
    padding-left: 0.2%;
    padding-right: 0.2%;
  }

  @media (max-width: 400px) {
    padding-top: 0.5%;
    padding-bottom: 0.2%;
    padding-left: 0.2%;
    padding-right: 0.2%;
  }
}

`

const ImageWrapper = styled.div`
@media (max-width:1200px) {
  display: none;
}

height: 100%;
width: 100%;
flex: 1 1 0;
/* box-shadow: 2px 2px 4px 4px rgba(0, 0, 0, 0.4); */

`

const ContactItemsWrapper = styled.div`
animation: 1s ease-out 0s 1 slideInFromLeft;

overflow-x: hidden;
overflow-y: scroll;

height: 100%;
width: 100%;
flex: 2 1 0;

.ContactItemsWrapper_content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  padding-left:6%;
  padding-right:6%;
}

.ContactItemsWrapper_title {
  width: 100%;
  height: 150px;
  
  display: flex;
  justify-content: left;
  align-items: center;
  /* margin-bottom: 35px; */

  @media (max-width: 900px) {  
    justify-content: center;
    align-items: center;
    /* margin-bottom: 0px; */
  }

  .ContactItemsWrapper_title_text {
    font-family: Montserrat, sans-serif;
    text-transform: uppercase;
    letter-spacing: 7px;
    font-size: 20px;
    font-feature-settings: normal;
    font-weight: 700;
    color: #1c1259;

    @media (max-width: 900px) {
      font-size: 30px;
    }
    @media (max-width: 400px) {
      font-size: 26px;
    }

  }
  .ContactItemsWrapper_title_line {
    flex: 1 1 0;

    width: 100%;
    height: 23.2px;
    display: flex;
    justify-content: center;
    align-items: center;

    .line {
      width: 100%;
      flex: 1 1 0;
      height: 100%;
      height: 1.5px;
      margin-left: 25px;
      margin-right: 25px;
      background-color: #1c1259;
    }

    @media (max-width: 900px) {
      display: none;
    }
  }

}

.ContactItemsWrapper_form {
  width: 100%;
  flex: 1 1 0;

  padding-bottom: 5%;
  @media (max-width: 500px) {
    padding-bottom: 10%;
  }
  @media (max-width: 350px) {
    padding-bottom: 13%;
  }

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  .ContactItemsWrapper_formField {
    margin-top: 15px;
    margin-bottom: 15px;
    width: 100%;
    height: 36px;

    padding-left: 10%;
    padding-right: 10%;
    @media (max-width: 800px) {
      padding-left: 5%;
      padding-right: 5%;
    }
    @media (max-width: 500px) {
      padding-left: 2%;
      padding-right: 2%;
    }
    @media (max-width: 400px) {
      padding-left: 1%;
      padding-right: 1%;
    }

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ContactItemsWrapper_formField .input-text {
    padding-left: 5px;
    padding-top: 7px;
    border-width: 0 0 1.5px 0;
    border-color: #662D91;
    font-family: Montserrat, sans-serif;
    letter-spacing: 1px;
    color: #1c1259;
    line-height: 26px;
    font-weight: 400;
    opacity: 0.8;
    
    font-size: 15px;
    @media (max-width: 400px) {
      font-size: 14px;
    }
  }

  .field_res {
    width: 100%;
    height: 36px;
  }

  .msg_body {
    width: 100%;
    height: 100px;
  }

  input::placeholder { 
    color: #1c1259;
  }
  textarea {
    resize: none;
  }
  textarea::placeholder{
    color: #1c1259;
  }
}

.ContactItemsWrapper_form .ContactItemsWrapper_formField .submit-btn {
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: #662D91;
  color: #fff;
  font-family: Montserrat, sans-serif;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
}

.ContactItemsWrapper_form .ContactItemsWrapper_formField .submit-btn:hover {
  background-color: white;
  color: black;
  font-weight: 700;
}

.button {
  --text: #662D91;
  --plane: #662D91;
  --background: #fff;
  &:hover {
    --background: #662D91;
    --text: #fff;
    --plane: #fff;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 0;
  padding: 0;
  width: 140px;
  height: 60px;
  background: none;
  color: var(--text);
  cursor: pointer;
  outline: none;
  svg.btn-layer {
    display: block;
    position: absolute;
    top: -20px;
    left: 0;
    width: 100%;
    height: 100px;
    z-index: 2;
    pointer-events: none;
    fill: var(--background);
  }
  svg.plane {
    z-index: 3;
    position: absolute;
    left: 32px;
    display: block;
    width: 26px;
    height: 28px;
    fill: var(--plane);
    transform: translate3d(0, 0, 0);
    perspective: 500px;
  }
  > ul {
    list-style: none;
    padding: 0 5px 0 0;
    position: relative;
    overflow: hidden;
    > li {
      display: inline-block;
      position: relative;
      z-index: 2;
      padding-left: 40px;
      font: 400 16px "Varela Round", sans-serif;
      transition: transform 0.3s ease 0s, opacity 0.3s ease 0s;
      &:first-child {
        opacity: 1;
      }
      &:last-child {
        position: absolute;
        left: 0;
        top: 100%;
        opacity: 0;
      }
    }
  }
  &.active {
    svg.plane {
      animation: 1.5s orbit alternate linear;
    }
    > ul {
      > li {
        transform: translateY(-100%);
        transition: transform 0.3s ease 1.2s, opacity 0.3s ease 1.2s;
        &:first-child {
          opacity: 0;
        }
        &:last-child {
          opacity: 1;
        }
      }
    }
  }
}

@keyframes orbit {
  0% {
    transform: rotate3d(1, 0, 0, 0deg) translateZ(60px) scale3d(1, 1, 1);
    animation-timing-function: ease-in;
  }
  10% {
    z-index: 3;
    transform: rotate3d(1, 0.6, 0, -10deg) translateZ(60px) scale3d(1, 1, 1);
    animation-timing-function: ease-out;
  }
  20% {
    z-index: 3;
    transform: rotate3d(1, 0.6, 0, 30deg) translateZ(60px) scale3d(1, 1, 1);
    animation-timing-function: ease-out;
  }
  30% {
    z-index: 3;
    transform: rotate3d(1, 0.6, 0, 35deg) translateZ(60px) scale3d(1, 1, 1);
  }
  40% {
    z-index: 1;
    transform: rotate3d(1, 0.6, 0, 120deg) translateZ(60px) scale3d(0.7, 0.7, 1);
  }
  70% {
    z-index: 1;
    transform: rotate3d(1, 0.6, 0, 240deg) translateZ(60px) scale3d(0.7, 0.7, 1);
    animation-timing-function: ease-out;
  }
  100% {
    z-index: 3;
    transform: rotate3d(1, 0, 0, 360deg) translateZ(60px) scale3d(1, 1, 1);
  }
}


`

export default Contact;