import React from 'react';
import imagemodal from '../assets/images/4.jpg'
import styled from 'styled-components';
import { Link } from "react-router-dom";


const Modal = ({ open, onClose }) => {
  if (!open) return null;
  return (
    <OverlayWrapper onClick={onClose}>
      <div className='blackBackGround'></div>
      <div onClick={(e) => { e.stopPropagation(); }} className='modalContainer'>
        <div className='closeBtn' onClick={onClose}>
          <div className="close-container">
            <div className="leftright"></div>
            <div className="rightleft"></div>
            <label className="close">close</label>
          </div>
        </div>

        <div className='imgmodal'>
          <center> <img src={imagemodal} alt='theres img here' id='imgmodalcons'></img></center>
        </div>

        <div className='content'>
          <div>
            Recently a few people working in smaller and sometimes even bigger companies in Germany reached out to me because they found themselves in a situation where their employer is handing them a termination or an “attractive” deal to leave the company.
          </div><br></br>
          <div>
            What was common in those cases was that the employer did not have a legal ground to terminate those people and therefore depended on the naivety of their employees of not being familiar with their employee rights in Germany or having legal protection insurance.
          </div><br></br>
          <div>
            What was also common was that the employees affected were women or people coming to Germany from developing countries or sadly a combination of both. Even in the case where the person had legal protection insurance the lawyer that took over their case, did not give it the required attention and was quite “judgy” not really believing that their client has been treated unfairly because “Germany has higher performance standards than other countries”.
          </div><br></br>
          <div>
            Being a woman and having lived and worked almost half of my professional career in a so-called “developing country” this hits very close to home. However, I consider myself quite lucky, because my roles in Human Resources have empowered me to know my rights as an employee and the rights of employees that I support. And fairness was always key, even in the uncomfortable situation of separation
          </div><br></br>
          <div>
            Long story short: If any woman or foreign employee working in Germany finds themselves in a situation similar to the one I mentioned here, I would be delighted to offer free consultation as far as my HR knowledge allows.
          </div><br></br>
          <div className='font_style_change'>
            Important disclaimer: I’m not an employment lawyer. My consultation is based on HR experience and does not equal legal advise
          </div><br></br>
          <div id="getintouch">
            <center>
              <Link to='/contact'>
                <button className="learn-more">
                  <span className="circle" aria-hidden="true">
                    <span className="icon arrow"></span>
                  </span>
                  <div className="button-text">
                    <span className="button-text">Get In Touch</span>
                  </div>
                </button>
              </Link>
            </center>
          </div>
        </div>
      </div>

    </OverlayWrapper>
  );
};

const OverlayWrapper = styled.div`
/* Modal */
position: absolute;
width: 100%;
z-index: 22;
height: 100%;
overflow-y: hidden;
margin: auto;
.blackBackGround
{
    position: absolute;
    z-index: 20;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
}

.modalContainer {
    width: 100%;
    position: absolute;
    width: 50%;
    left: 25%;
    z-index: 22;
    height: 90%;
    overflow-y: scroll;
    margin: auto;
    background-color: #ffffff;
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.75);
    border-radius: 8px;
    top: 1%;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding-top: 3%;
  padding-left: 3%;
  padding-right: 3%;
  height: 30%;
  /* color: #2F3C7E; */
  color: #333;
  /* font-size: 9px; */
  overflow: scroll;  
  height :auto ;
  font-size: 15px;
  font-weight: 400;
  
  .font_style_change {
    font-style: italic;
  }
}
.contentprag
{
  font-size:15px;
}

.closeBtn {

  position: absolute;
  /* height: 10px; */
  top: 3px;
  right: 1px;
  width: auto;


  
.close-container {
  position: relative;
  margin: auto;
  width: 50px;
  height: 50px;
  /* margin-top: 100px; */
  
  cursor: pointer;
}

.leftright {
  height: 2.5px;
  width: 30px;
  position: absolute;
  margin-top: 24px;
  background-color: black;
  border-radius: 2px;
  transform: rotate(45deg);
  transition: all 0.3s ease-in;
}

.rightleft {
  height: 2.5px;
  width: 30px;
  position: absolute;
  margin-top: 24px;
  background-color: black;
  border-radius: 2px;
  transform: rotate(-45deg);
  transition: all 0.3s ease-in;
}

label {
  color: black;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 0.6em;
  text-transform: uppercase;
  letter-spacing: 2px;
  transition: all 0.3s ease-in;
  opacity: 0;
}

.close {
  margin: 60px 0 0 5px;
  position: absolute;
}

.close-container:hover .leftright {
  transform: rotate(-45deg);
  background-color: #F25C66;
}

.close-container:hover .rightleft {
  transform: rotate(45deg);
  background-color: #F25C66;
}

.close-container:hover label {
  opacity: 1;
}
}
.imgmodal
{
  height: auto;
}
#imgmodalcons {
  max-width:80%;
}
 button {
	 position: relative;
	 display: inline-block;
	 cursor: pointer;
	 outline: none;
	 border: 0;
	 vertical-align: middle;
	 text-decoration: none;
	 background: transparent;
	 padding: 0;
	 font-size: inherit;
	 font-family: inherit;
   margin-left: -7%;
}
 button.learn-more {
	 width: 12rem;
	 height: auto;
}
 button.learn-more .circle {
	 transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
	 position: relative;
	 display: block;
	 margin: 0;
	 width: 3rem;
	 height: 3rem;
	 background: #662D91;
	 border-radius: 1.625rem;
}
 button.learn-more .circle .icon {
	 transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
	 position: absolute;
	 top: 0;
	 bottom: 0;
	 margin: auto;
	 background: #fff;
}
 button.learn-more .circle .icon.arrow {
	 transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
	 left: 0.625rem;
	 width: 1.125rem;
	 height: 0.125rem;
	 background: none;
}
 button.learn-more .circle .icon.arrow::before {
	 position: absolute;
	 content: '';
	 top: -0.25rem;
	 right: 0.0625rem;
	 width: 0.625rem;
	 height: 0.625rem;
	 border-top: 0.125rem solid #fff;
	 border-right: 0.125rem solid #fff;
	 transform: rotate(45deg);
}
 button.learn-more .button-text {
	 transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
	 position: absolute;
	 top: 0;
	 left: 0;
	 right: 0;
	 bottom: 0;
	 padding: 0.75rem 0;
	 margin: 0 0 0 1.85rem;
	 color: #282936;
	 font-weight: 700;
	 line-height: 1.6;
	 text-align: center;
	 text-transform: uppercase;
   font-size: 14px;
}
 button:hover .circle {
	 width: 100%;
}
 button:hover .circle .icon.arrow {
	 background: #fff;
	 transform: translate(1rem, 0);
}
 button:hover .button-text {
	 color: #fff;
   font-size: 15px;
}
 @supports (display: grid) {
	 body {
		 display: grid;
		 grid-template-columns: repeat(4, 1fr);
		 grid-gap: 0.625rem;
		 grid-template-areas: ". main main ." ". main main .";
	}
	 #getintouch {
    margin-top: 1%;
    margin-bottom: 2%;
		 grid-area: main;
		 align-self: center;
		 justify-self: center;
	}
} 

@media (max-width:900px) {
  .modalContainer {
    height: 70%;
     width: 80%;
     top: 10%;
    left: 10%;
  }
}

.getInTouch {
    /* text-transform: uppercase; */
    background-color: #662D91;//#262325;
    color: #fff;
    width: fit-content;
    padding: 1rem 1.5rem;
    font-size: 1.5rem;
    font-weight: 700;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    cursor: pointer;
    border: solid 1px transparent;
    transition: all 0.5s ease;
    &:hover {
        background-color: #fff;
        border: solid 1px #262325;
        
        a{
            color: black;
        }
    }
    a{
        text-decoration: none;
        color: #fff;
    }
}

`

export default Modal;
