import styled from "styled-components";
import "../index.css"

import { Link } from "react-router-dom";


const privacy = () => {
  return (
    <PrivacyContainer>
    
        <div className="PrivacyItem">
          <div className="PrivacyItem_mainHeader"> <h1> Privacy Policy </h1> </div>
          <p className="PrivacyItem_mainParag">
            We take the protection of your personal data very seriously. 
            We treat your personal data confidentially and in accordance with data protection law. 
            This privacy policy informs you about how, to what extent, 
            and for what purposes we process personal data when using www.yasminahmed.com.
          </p>
        </div>

        <div className="PrivacyItem">
          <div className="PrivacyItem_header"> <p> 1. Collection, processing, and use of personal data on request </p> </div>
          <p className="PrivacyItem_Parag">
            The use of our website is generally possible without providing personal data. 
            You are neither obliged to visit this website nor to provide any personal data. 
            If you do not provide us with personal information, you may not be able to use certain functionalities of this website. 
            Otherwise, there will be no consequences for you. 
            As far as personal information (such as name, address or e-mail addresses) is collected on our site, 
            this is done on a voluntary basis, except in the cases expressly described below. 
            This data will not be passed on to third parties without your express consent. 
            We would like to point out that data transmission over the Internet (e.g. communication by e-mail) can have security risks. 
            A complete protection of data against access by third parties is not possible. 
            If the processing of your personal data is based on your consent, 
            you have the right to revoke your consent at any time with the consequence that the processing of your personal 
            data will become inadmissible for the future. However, this does not affect the legality of the processing 
            carried out on the basis of the consent until revocation.
          </p>
        </div>

        <div className="PrivacyItem">
          <div className="PrivacyItem_header"> <p> 2. Data processing to enable the use of the website </p> </div>
          <p className="PrivacyItem_Parag">
            When you visit our website, a so-called "cookie banner" appears, with which we ask you for your consent to set cookies to optimize the website (see paragraph 4) and for web analysis 
            (see paragraph 5). If you agree, we will proceed as described in more detail in the following two sections. There you will also find information 
            on how you can revoke your consent at any time and prevent the setting of cookies or web analysis.
          </p>
        </div>

        <div className="PrivacyItem">
          <div className="PrivacyItem_header"> <p> 3. Consent to cookies and web analysis </p> </div>
          <p className="PrivacyItem_Parag">
            When you visit our website, a so-called "cookie banner" appears, 
            with which we ask you for your consent to set cookies to optimize the website (see paragraph 4) 
            and for web analysis (see paragraph 5). If you agree, we will proceed as described in more detail in the following two sections. 
            There you will also find information on how you can revoke your consent at any time and prevent the setting of cookies or web analysis.
          </p>
        </div>

        <div className="PrivacyItem">
          <div className="PrivacyItem_header"> <p> 4. Cookies </p> </div>
          <p className="PrivacyItem_Parag">
            If you visit our website and give your consent (see section 3), it may be that information in the form of a cookie is stored on your computer. 
            Cookies are small text files that are sent from a web server to your browser and stored on your computer's hard drive. This makes 
            it possible to recognize you when you visit the website again. In this way we can guarantee a better functionality of the site and 
            carry out e.g. web analyses (see paragraph 5). Most browsers are set to automatically accept cookies. You can deactivate the storage 
            of cookies in your browser and have the possibility to delete them from your hard disk at any time. We would like to point out to you that a 
            use of our offers on the website without cookies may only be possible to a limited extent. However, you can also use your browser only to prevent certain cookies 
            from being set (e.g. cookies from third parties), for example, if you want to prevent web tracking. Please refer to your browser's help function for more information.
          </p>
        </div>

        <div className="PrivacyItem">
          <div className="PrivacyItem_header"> <p> 5. Web analysis (Google Analytics) </p> </div>
          <p className="PrivacyItem_Parag">
            If you visit our website and consent (see paragraph 3), we use Google Analytics, a web analysis service of Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA (Google). Google Analytics uses cookies (see section 4) to enable an analysis of your use of the website. The information about your use of this website is usually transferred to a Google server in the USA and stored there. However, Google will reduce your IP address within member states of the European Union or in other signatory states to the Agreement on the European Economic Area beforehand and thus make it anonymous. Only in exceptional cases will the full IP address be transmitted to a Google server in the USA and shortened there. On behalf of the operator of this website, Google will use this information to evaluate your use of the website in order to compile reports on website activity.
            You can revoke your consent to web analysis and the related use of cookies as follows: On the one hand, the storage of cookies can be prevented by a corresponding setting of your browser software (see section 4). Secondly, you can prevent the collection and transmission of data relating to your use of the website (including your IP address) to Google and the processing of this data by Google by downloading and installing the browser plug-in available under the following link. Alternatively, you can prevent Google Analytics from collecting data by setting an "Opt-Out-Cookie" on your computer; please use the following link: Opt-Out-Cookie
            Further information on data protection at Google Analytics can be found <a rel="noreferrer" href="https://www.google.de/intl/de/policies/" target="_blank">here</a>.
          </p>
        </div>

        <div className="PrivacyItem">
          <div className="PrivacyItem_header"> <p> 6. Youtube-Videos </p> </div>
          <p className="PrivacyItem_Parag">
            On our website videos are embedded which are provided by the provider YouTube LLC, 901 Cherry Avenue, 
            San Bruno, CA 94066, USA, represented by Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA. 
            We have integrated the YouTube videos with YouTube's "enhanced privacy mode". This prevents data about your visit to YouTube from 
            being processed or cookies from being placed on your computer by YouTube when you simply call up a page with an embedded video. 
            When you click on a video, your IP address is transmitted to YouTube and YouTube learns that you have viewed the video. 
            If you are logged in to YouTube, this information is also assigned to your user account (you can prevent this by logging 
            out of YouTube before viewing the video). We have no knowledge and no control over the collection and use of your 
            information by YouTube when you view a video. For more information, 
            please refer to YouTube's privacy policy <a rel="noreferrer" href="https://www.google.de/intl/de/policies/privacy/" target="_blank">here</a>.
          </p>
        </div>

        <div className="PrivacyItem">
          <div className="PrivacyItem_header"> <p> 7. Legal basis for processing </p> </div>
          <p className="PrivacyItem_Parag">
            The legal basis for processing depends on the purpose for which the data are processed. We collect, 
            as described under item 5, data for pseudonyms user profiles to improve the website on the basis of 
            the applicable data protection law for media.
          </p>
        </div>

        <div className="PrivacyItem">
          <div className="PrivacyItem_header"> <p> 8. Transmission to third parties and in countries outside the EU </p> </div>
          <p className="PrivacyItem_Parag">
            Your personal data will only be transmitted to third parties, if this is legally 
            permitted or if you have given your prior consent. We will only disclose your data to 
            government authorities within the framework of legal obligations or as a result of an official 
            order or court decision. A transfer to recipients outside the EU only takes place if it is ensured 
            that the recipient of the data guarantees an adequate level of data protection and that there are no other 
            interests worthy of protection against the data transfer. 
            Should you have any questions in this regard, please contact <div className="RedTextInBetween">contact@yasminahmed.com</div> (see Section 11).
          </p>
        </div>

        <div className="PrivacyItem">
          <div className="PrivacyItem_header"> <p> 9. Deletion </p> </div>
          <p className="PrivacyItem_Parag">
            The data collected by Google Analytics in the form of pseudonymous user profiles (section 5) will be deleted no later than 
            38 months after the last new entry in the respective user profile. In all other respects, we delete your personal data as 
            soon as they are no longer needed for the purposes pursued with the collection and processing, and as far as no legal storage obligations stand in the way.
          </p>
        </div>

        <div className="PrivacyItem">
          <div className="PrivacyItem_header"> <p> 10. Data security </p> </div>
          <p className="PrivacyItem_Parag">
            Yasmin Ahmed has taken the necessary technical and organizational measures to protect the personal data you provide from loss, 
            destruction, manipulation and unauthorized access. All persons involved in data processing are obliged to comply with data protection 
            laws and to treat personal data confidentially. Both internal and external tests ensure compliance with all data protection relevant 
            processes at www.yasminahmed.com. To protect the personal data of our users, we use a secure online transmission procedure, the so-called
             "Secure Socket Layer" (SSL) transmission. You can recognize this by the fact that an "s" ("https://") or a green, closed lock symbol 
             is added to the address component. By clicking on the icon, you will receive information about the SSL certificate used. The display 
             of the symbol depends on the browser version you are using. SSL encryption guarantees the encrypted and complete transmission of your data.
          </p>
        </div>

        <div className="PrivacyItem">
          <div className="PrivacyItem_header"> <p> 11. Your rights regarding personal data </p> </div>
          <p className="PrivacyItem_Parag">
            The data protection law grants you a number of rights with 
            regard to data concerning your person (so-called rights of data subjects). In general, these are the right to request 
            information about your personal data stored by us, as well as the right to correct, delete or restrict the processing 
            of these data and to object to the processing. Whether and to what extent these rights exist in individual cases and 
            what conditions apply depends on the law (until 25 May 2018 from the Federal Data Protection Act, from 25 May 2018 also 
            from the EU Basic Data Protection Ordinance). The basic EU data protection regulation also grants you the right to data 
            transferability. If you have given your consent to the processing of your personal data, you can revoke this at any time 
            with effect for the future. They also have a right of appeal to the competent data protection supervisory authority. However, if 
            you have any questions or complaints regarding data protection at www.yasminahmed.com we recommend that you can first reach 
            out to <div className="RedTextInBetween">contact@yasminahmed.com</div> (see Section 12).</p>
        </div>

        <div className="PrivacyItem">
          <div className="PrivacyItem_header"> <p> 12. No automated single decision </p> </div>
          <p className="PrivacyItem_Parag">
            As far as this is not exceptionally necessary for the conclusion of a contract or permitted by law (as in the case of age verification) we do not use your personal data for automated individual decisions.
          </p>
        </div>

        <div className="PrivacyItem">
          <div className="PrivacyItem_header"> <p> 13. How can you contact us? </p> </div>
          <p className="PrivacyItem_Parag">
            You will find our contact details as the responsible body in the <Link className="ImprintLinkStyle" to='/imprints'>imprint</Link>. 
            If you wish to exercise the rights mentioned under item 10 or if you have any 
            questions about data protection with us or this data protection declaration, you can also contact: <div className="RedTextInBetween">contact@yasminahmed.com</div>
          </p>
        </div>

        <div className="PrivacyItem">
          <div className="PrivacyItem_header"> <p> 14. Amendment of the privacy policy </p> </div>
          <p className="PrivacyItem_Parag">
            New legal requirements, business decisions, or technical developments may require changes to our privacy policy. The privacy policy will then be adapted accordingly. You can always find the latest version on our website.
          </p>
        </div>

    </PrivacyContainer>
  );
}

const PrivacyContainer = styled.div`
animation: 1s ease-out 0s 1 slideInFromLeft;

width: 100%;
height: 100%;

/* background-color: rgba(250, 249, 246, 1); */
background-color: white;

color: rgba(0,0,0,0.9);
font-family: 'Montserrat', sans-serif ;

display: flex;
flex-direction: column;

overflow-x: hidden;
overflow-y: scroll;

padding-top: 0.3%;
padding-bottom: 0.5%;
padding-left: 3%;
padding-right: 3%;

@media (max-width: 900px) {
  padding-top: 0.6%;
  padding-bottom: 0.5%;
}

.PrivacyItem {
  width: 100%;

  margin-top: 0.3%;
  margin-bottom: 0.3%;
  @media (max-width: 900px) {
  padding-top: 3%;
  padding-bottom: 3%;
}
}

.PrivacyItem_mainHeader {
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 0;

  margin-top: 1%;
  margin-bottom: 1%;

  @media (max-width: 900px) {

  }

  @media (max-width: 400px) {
    margin-top: 3%;
    margin-bottom: 3%;
    font-size: small;
  }
}

.PrivacyItem_mainParag {
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 0;

  padding-left: 1%;
  padding-right: 1%;

  font-size: medium;
  font-weight: 100;

  line-height: 22px;
  margin-top: 0.4%;
  margin-bottom: 0.1%;

  @media (max-width: 400px) {
    font-size: 13px;
  }
}

.PrivacyItem_header {
  width: 100%;

  display: flex;
  justify-content: left;
  align-items: center;
  flex: 1 1 0;

  font-size: large;
  font-weight: bold;

  line-height: 22px;
  
  margin-top: 0.3%;
  margin-bottom: 0.3%;

  @media (max-width: 400px) {
    margin-top: 1.0%;
    margin-bottom: 1.0%;
    font-size: 14px;
  }
}

.PrivacyItem_Parag {
  width: 100%;

  display: inline;

  font-size: medium;
  font-weight: 100;

  line-height: 22px;
  
  margin-top: 0.3%;
  margin-bottom: 0.3%;

  @media (max-width: 400px) {
    margin-top: 1.0%;
    margin-bottom: 1.0%;
    font-size: 13px;
  }

  a {
      color: rgba(0,0,0,0.95);
      cursor: pointer;
  }

  .ImprintLinkStyle{
    color: red;
    font-weight: 600;
  }

  .RedTextInBetween {
    display: inline;
    color: red;
    font-weight: 700;
  }
}


`


export default privacy;