import React from 'react';
import imagemodal from '../assets/images/3.jpg'
import styled from 'styled-components';
import { Link } from "react-router-dom";


const Modal = ({ open, onClose }) => {
  if (!open) return null;
  return (
    <OverlayWrapper onClick={onClose}>
      <div className='blackBackGround'></div>
      <div onClick={(e) => { e.stopPropagation(); }} className='modalContainer'>
        <div className='closeBtn' onClick={onClose}>
          <div className="close-container">
            <div className="leftright"></div>
            <div className="rightleft"></div>
            <label className="close">close</label>
          </div>
        </div>

        <div className='imgmodal'>
          <center> <img alt='theres img here' src={imagemodal} id='imgmodalcons'></img></center>
        </div>

        <div className='content'>
          <h1>
            On project or interim basis I offer business related HR solutions based on my experience in the following fields:
          </h1><br></br><br></br>
          <div className='contentPrag'>
            ●	Building & Scaling HR Teams for Start-Ups & Scale-Ups
          </div><br></br>
          <div className='contentPrag'>
            ●	Leadership Development
          </div><br></br>
          <div className='contentPrag'>
            ●	Engagement Strategies
          </div><br></br>
          <div className='contentPrag'>
            ●	Org Design
          </div><br></br>
          <div className='contentPrag'>
            ●	Values’ Creation
          </div><br></br>
          <div className='contentPrag'>
            ●	Change Management
          </div><br></br>
          <div className='contentPrag'>
            ●	Layoffs
          </div><br></br>
          <div className='contentPrag'>
            ●	Career Development Frameworks
          </div><br></br>
          <div className='contentPrag'>
            ●	Designing OKRs
          </div><br></br>
          <div id="getintouch">
            <center>
              <Link to='/contact'>
                <button className="learn-more">
                  <span className="circle" aria-hidden="true">
                    <span className="icon arrow"></span>
                  </span>
                  <div className="button-text">
                    <span className="button-text">Get In Touch</span>
                  </div>
                </button>
              </Link>
            </center>
          </div>
        </div>
      </div>

    </OverlayWrapper>
  );
};


const OverlayWrapper = styled.div`
/* Modal */
position: absolute;
width: 100%;
z-index: 22;
height: 100%;
overflow-y: hidden;
margin: auto;
.blackBackGround
{
    position: absolute;
    z-index: 20;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
  }
  
  .modalContainer {
    width: 100%;
    position: absolute;
    width: 50%;
    left: 25%;
    z-index: 22;
    height: 90%;
    overflow-y: scroll;
    margin: auto;
    background-color: #ffffff;
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.75);
    border-radius: 8px;
    top: 1%;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    padding-top: 3%;
    padding-left: 3%;
    padding-right: 3%;
    height: 30%;
    /* color: #2F3C7E; */
    color: #333;
    /* margin-top: 2%; */
    font-size: 9px;
    overflow: scroll;  
    height :auto ;

    h1 {
      font-size: medium;
    }
}
.contentPrag
{
  font-size:15px;
}

.closeBtn {

  position: absolute;
  /* height: 10px; */
  top: 3px;
  right: 0px;
  width: auto;


  
.close-container {
  position: relative;
  margin: auto;
  width: 50px;
  height: 50px;
  /* margin-top: 100px; */
  
  cursor: pointer;
}

.leftright {
  height: 2.5px;
  width: 30px;
  position: absolute;
  margin-top: 24px;
  background-color: black;
  border-radius: 2px;
  transform: rotate(45deg);
  transition: all 0.3s ease-in;
}

.rightleft {
  height: 2.5px;
  width: 30px;
  position: absolute;
  margin-top: 24px;
  background-color: black;
  border-radius: 2px;
  transform: rotate(-45deg);
  transition: all 0.3s ease-in;
}

label {
  color: black;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 0.6em;
  text-transform: uppercase;
  letter-spacing: 2px;
  transition: all 0.3s ease-in;
  opacity: 0;
}

.close {
  margin: 60px 0 0 5px;
  position: absolute;
}

.close-container:hover .leftright {
  transform: rotate(-45deg);
  background-color: #F25C66;
}

.close-container:hover .rightleft {
  transform: rotate(45deg);
  background-color: #F25C66;
}

.close-container:hover label {
  opacity: 1;
}
}

.imgmodal
{
  height: auto;
}
#imgmodalcons {
  max-width:80%;
}
 button {
	 position: relative;
	 display: inline-block;
	 cursor: pointer;
	 outline: none;
	 border: 0;
	 vertical-align: middle;
	 text-decoration: none;
	 background: transparent;
	 padding: 0;
	 font-size: inherit;
	 font-family: inherit;
   margin-left: -7%;
}
 button.learn-more {
	 width: 12rem;
	 height: auto;
}
 button.learn-more .circle {
	 transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
	 position: relative;
	 display: block;
	 margin: 0;
	 width: 3rem;
	 height: 3rem;
	 background: #662D91;
	 border-radius: 1.625rem;
}
 button.learn-more .circle .icon {
	 transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
	 position: absolute;
	 top: 0;
	 bottom: 0;
	 margin: auto;
	 background: #fff;
}
 button.learn-more .circle .icon.arrow {
	 transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
	 left: 0.625rem;
	 width: 1.125rem;
	 height: 0.125rem;
	 background: none;
}
 button.learn-more .circle .icon.arrow::before {
	 position: absolute;
	 content: '';
	 top: -0.25rem;
	 right: 0.0625rem;
	 width: 0.625rem;
	 height: 0.625rem;
	 border-top: 0.125rem solid #fff;
	 border-right: 0.125rem solid #fff;
	 transform: rotate(45deg);
}
 button.learn-more .button-text {
	 transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
	 position: absolute;
	 top: 0;
	 left: 0;
	 right: 0;
	 bottom: 0;
	 padding: 0.75rem 0;
	 margin: 0 0 0 1.85rem;
	 color: #282936;
	 font-weight: 700;
	 line-height: 1.6;
	 text-align: center;
	 text-transform: uppercase;
   font-size: 14px;
}
 button:hover .circle {
	 width: 100%;
}
 button:hover .circle .icon.arrow {
	 background: #fff;
	 transform: translate(1rem, 0);
}
 button:hover .button-text {
	 color: #fff;
   font-size: 15px;
}
 @supports (display: grid) {
	 body {
		 display: grid;
		 grid-template-columns: repeat(4, 1fr);
		 grid-gap: 0.625rem;
		 grid-template-areas: ". main main ." ". main main .";
	}
	 #getintouch {
    margin-top: 1%;
    margin-bottom: 2%;
		 grid-area: main;
		 align-self: center;
		 justify-self: center;
	}
} 


@media (max-width:900px) {
  .modalContainer {
    height: 70%;
     width: 80%;
     top: 10%;
    left: 10%;
  }
}
`

export default Modal;
