import { Link } from "react-router-dom";
import styled from "styled-components";

const Footer = (props) => {
    return (
        <FooterContainer>
            <div className="Footer_items_copyright">
                Copyright © 2023
            </div>

            <div className="Footer_items_privacy">
                <Link to='/privacy'>Privacy Policy</Link>
                &nbsp;|&nbsp;
                <Link to='/imprint'>Imprint</Link>
            </div>

            <div className="Footer_items_logos">
                <a href="https://www.linkedin.com/in/yasmin-ahmed-a60ba021/" target='_blank' rel="noreferrer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25px" fill="#0072b1" className="bi bi-linkedin" viewBox="0 0 16 16"> <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" /> </svg>
                </a>
            </div>
        </FooterContainer>
    )
}

const FooterContainer = styled.div`
display: flex;

width: 100%;
height: 100%;

font-family: 'Montserrat', sans-serif;

.Footer_items_copyright {
    display: flex;
    font-size: small;

    width: 33%;
    height: 100%;

    align-items: center;

    color: rgba(0,0,0,0.95);

    padding-left: 25px;
}

.Footer_items_privacy {
    display: flex;

    width: 33%;
    height: 100%;

    align-items: center;
    justify-content: center;

    color: rgba(0,0,0,0.6);
    
    a{
        font-size: 14px;
        font-weight: 500;
        color: rgba(0,0,0,0.95);
        cursor: pointer;
    }
}

.Footer_items_logos {
    display: flex;

    width: 33%;
    height: 100%;

    align-items: center;
    justify-content: right;

    padding-right: 25px;

    a{
        text-decoration: none;
    }
}

@media (max-width: 500px) 
{
    .Footer_items_copyright {
        padding-left: 10px;
        font-size: 13px;
        width: 32%;
    }

    .Footer_items_privacy {
        width: 40%;
        
        a {
            font-size: 13px;
        }
    }

    .Footer_items_logos {
        width: 28%;
        gap: 3px;
        padding-right: 20px;
    }
}

@media (max-width: 350px) 
{
    .Footer_items_copyright {
        padding-left: 8px;
        font-size: 12px;
        width: 33%;
    }

    .Footer_items_privacy {
        width: 45%;
        
        a {
            font-size: 12px;
        }
    }

    .Footer_items_logos {
        width: 22%;
        gap: 3px;
        padding-right: 8px;
    }
}


`

export default Footer
