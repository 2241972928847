import styled from "styled-components";

const EmailSentPopUp = ({open, onClose, email_send_success}) => {
    
    if (!open) return null;

    const buttonOnClickProcess = () => {
        onClose();
    }

    const backgroundClickProcess = () => {
        onClose();
    }

    let disp_text;
    if(email_send_success == null)
    {
        disp_text = <h2>404</h2>
    }
    else if (email_send_success === true)
    {
        disp_text = <h2>Thank you for your message! We will get in touch as soon as possible</h2>
    }
    else
    {
        disp_text = <h2>Looks like your network is not working properly, please try again later</h2>
    }

    return (
        <EmailSentPopUpContainer onClick={backgroundClickProcess}>
            <div className="PopUpContainer">
                {disp_text}
                <button onClick={buttonOnClickProcess}><p>Done</p></button>
            </div>
        </EmailSentPopUpContainer>
    )
}

const EmailSentPopUpContainer = styled.div`

position: absolute;
height: 100%;
width: 100%;
font-family: 'Montserrat', sans-serif;

z-index: 1000;

display: flex;
justify-content: center;
align-items: center;

background-color: rgba(0,0,0,0.75);

transition: opacity 200ms;

.PopUpContainer {
    border-radius: 30px;
    width: 33%;
    height: 30%;

    @media (max-width: 1100px)  {
        width: 45%;
        height: 35%;
    }

    @media (max-width: 900px)  {
        width: 70%;
        height: 35%;
    }

    @media (max-width: 600px)  {
        width: 85%;
        height: 35%;
    }

    background-color: rgba(255,255,255,0.7);

    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;

    h2 {
        /* padding: 10px; */
        margin: 10px;
        letter-spacing: 2px;
        font-size: 1.2rem;
        font-weight: 500;
        text-align: center;
        /* text-justify: inter-word; */
        word-wrap: break-all;
    }

    button {
        border-radius: 15px;
        background-color: #662D91;
        color: #fff;
        width: fit-content;
        padding: 1rem 1.5rem;
        font-size: 1.2rem;
        font-weight: 500;
        cursor: pointer;
        border: solid 1px transparent;
        transition: all 0.5s ease;
        &:hover {
            background-color: #fff;
            border: solid 1px #262325;
            
            p{
                color: black;
            }
        }
        p{
            text-decoration: none;
            color: #fff;
        }
    }

}

`

export default EmailSentPopUp
