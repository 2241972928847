import styled from "styled-components";
import { useState } from "react";

import ImageComponent from "../components/ImageComponent";
import ServiceComponent from "../components/ServicesComponent";
import ChoachingModal from "../components/coaching_popup";
import ConsultingModal from "../components/consulting_popup";
import GivingBackModal from "../components/givingBack_popup";


import portfolio_img_1_flipped from '../assets/images/1_flipped.jpg';

import SVGIconCoaching from "../components/svg_icons/svg_logo_coaching.js"
import SVGIconConsulting from "../components/svg_icons/svg_logo_consulting.js"
import SVGIconGivingback from "../components/svg_icons/svg_logo_givingback.js"

const Services = () => {
  const [openCoachingModal, setOpenCoachingModal] = useState(false);

  const [openConsultingModal, setOpenConsultingModal] = useState(false);

  const [openGivingBackModalModal, setOpenGivingBackModalModal] = useState(false);

  function MySVGCoaching() {
    return (

      <SVGIconCoaching
        dim_height="75"
        dim_width="75"
        styles={{ objectFit: 'contain' }}
        fill=""
        hoverFill="#165688"
      />
    );
  }

  function MySVGConsulting() {
    return (
      <SVGIconConsulting
        dim_height="60"
        dim_width="60"
        styles={{ objectFit: 'contain' }}
        fill=""
        hoverFill="#165688"
      />
    );
  }

  function MySVGGivingback() {
    return (
      <SVGIconGivingback
        dim_height="125"
        dim_width="125"
        styles={{ objectFit: 'contain' }}
        fill=""
        hoverFill="#165688"
      />
    );
  }

  var text3 = <p>If you are a woman or a foreign employee based in Germany that is confronted with an employment termination, reach out for <strong style={{ fontWeight:700 }}>free HR consultation</strong> as far as my knowledge allows it.</p>
  return (
    <ServicesContainer>
      <GivingBackModal open={openGivingBackModalModal} onClose={() => setOpenGivingBackModalModal(false)} />
      <ChoachingModal open={openCoachingModal} onClose={() => setOpenCoachingModal(false)} />
      <ConsultingModal open={openConsultingModal} onClose={() => setOpenConsultingModal(false)} />
      <div className="ServicesContent">
        <ImageWrapper>
          <ImageComponent ImgSrc={portfolio_img_1_flipped}/>
        </ImageWrapper>
        <ServicesItemsWrapper>
          {/* <Choaching></Choaching> */}
          <div className="ServicesItemsWrapper_layout" id="touch">
            <div className="ServicesItemsWrapper_title" id="touch">
              <div className="ServicesItemsWrapper_title_text">
                Services
              </div>
              <div className="ServicesItemsWrapper_title_line">
                <div className="line"></div>
              </div>
            </div>

            <div className="ServicesItemsWrapper_content">
              <div className="ServicesItemsWrapper_contentField1" onClick={() => setOpenConsultingModal(true)}>
                <ServiceComponent
                  ContentSVG={MySVGConsulting}
                  title="Consulting"
                  describe_text="On project or interim basis, I offer business related HR solutions">
                </ServiceComponent>
              </div>

              <div className="ServicesItemsWrapper_contentField2" onClick={() => setOpenCoachingModal(true)}>
                <ServiceComponent
                  ContentSVG={MySVGCoaching}
                  title="Coaching"
                  describe_text="I offer coaching for anything related to human relationships">
                </ServiceComponent>
              </div>

              <div className="ServicesItemsWrapper_contentField3" onClick={() => setOpenGivingBackModalModal(true)}>
                <ServiceComponent
                  ContentSVG={MySVGGivingback}
                  title="Giving Back"
                  describe_text={text3}>
                </ServiceComponent>
              </div>

            </div>
          </div>
        </ServicesItemsWrapper>

      </div>
    </ServicesContainer>
  );
}

const ServicesContainer = styled.div`

width: 100%;
height: 100%;

/* background-color: rgba(250, 249, 246, 1); */
background-color: white;
overflow: hidden;

color: rgba(0,0,0,0.9);
font-family: 'Montserrat', sans-serif;

.ServicesContent {
  width: 100%;
  height: 100%;
  
  padding-top: 0.3%;
  padding-bottom: 0.3%;
  padding-left: 5%;
  /* padding-right: 0.2%; */

  display: flex;

  @media (max-width: 900px) {
    overflow-x: hidden;
    overflow-y: scroll;
  }

  @media (max-width: 900px) {
    padding-top: 0.5%;
    padding-bottom: 0.1%;
    padding-left: 0.2%;
    padding-right: 0.2%;
  }

  @media (max-width: 400px) {
    padding-top: 0.5%;
    padding-bottom: 0.2%;
    padding-left: 0.2%;
    padding-right: 0.2%;
  }
}

`

const ImageWrapper = styled.div`
@media (max-width:1200px) {
  display: none;
}

height: 100%;
width: 100%;
flex: 1 1 0;
/* box-shadow: 2px 2px 4px 4px rgba(0, 0, 0, 0.4); */

`

const ServicesItemsWrapper = styled.div`
animation: 1s ease-out 0s 1 slideInFromLeft;

overflow: hidden;
@media (max-width: 900px) {
  overflow-x: hidden;
  overflow-y: scroll;
}

height: 100%;
width: 100%;
flex: 2 1 0;

.ServicesItemsWrapper_layout {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  padding-left:6%;
  padding-right:6%;
}

.ServicesItemsWrapper_title {
  width: 100%;
  height: 150px;
  
  display: flex;
  justify-content: left;
  align-items: center;
  /* margin-bottom: 35px; */

  @media (max-width: 900px) {  
    justify-content: center;
    align-items: center;
    height: 80px;
  }
  @media (max-width: 800px) {
    justify-content: center;
    align-items: center;
    height: 60px;
  }
  @media (max-width: 500px) {
    justify-content: center;
    align-items: center;
    height: 50px;
  }

  .ServicesItemsWrapper_title_text {
    font-family: Montserrat, sans-serif;
    text-transform: uppercase;
    letter-spacing: 7px;
    font-size: 20px;
    font-feature-settings: normal;
    font-weight: 700;
    color: #1c1259;

    @media (max-width: 900px) {
      font-size: 30px;
    }
    @media (max-width: 400px) {
      font-size: 26px;
    }

  }
  .ServicesItemsWrapper_title_line {
    flex: 1 1 0;

    width: 100%;
    height: 23.2px;
    display: flex;
    justify-content: center;
    align-items: center;

    .line {
      width: 100%;
      flex: 1 1 0;
      height: 100%;
      height: 1.5px;
      margin-left: 25px;
      margin-right: 25px;
      background-color: #1c1259;
    }

    @media (max-width: 900px) {
      display: none;
    }
  }

}

.ServicesItemsWrapper_content {
  width: 100%;
  height: 100%;
  flex: 1 1 0;

  padding-bottom: 5%;
  @media (max-width: 500px) {
    padding-bottom: 10%;
  }
  @media (max-width: 350px) {
    padding-bottom: 13%;
  }

  display: grid;
  grid-template-columns: repeat(10, minmax(9%, 10%));

  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-content: center;

  justify-items: center;
  align-items: center;

  overflow-y: scroll;
  overflow-x: hidden;

  .ServicesItemsWrapper_contentField1 {
    grid-column: 2/6;
  }
  .ServicesItemsWrapper_contentField2 {
    grid-column: 6/10;
  }
  .ServicesItemsWrapper_contentField3 {
    grid-column: 4/8;
  }

  @media (max-width: 900px) {
    padding-top: 20px;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, minmax(350px, auto));
    grid-column-gap: 0px;
    grid-row-gap: 50px;

    justify-items: stretch;

    .ServicesItemsWrapper_contentField1 {
      grid-column: 1/-1;
      grid-row: 1;
    }
    .ServicesItemsWrapper_contentField2 {
      grid-column: 1/-1;
      grid-row: 2;
    }
    .ServicesItemsWrapper_contentField3 {
      grid-column: 1/-1;
      grid-row: 3;
    }
  }
}


`

export default Services;