// import React from "react";
// import ReactDOM from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Blacklogo from '../assets/logos/purple logo.jpeg'
import NavbarMobButtonImg from "../assets/icons/navbar_icon.png"
import { useEffect, useRef } from "react";


const Header = () => {

  const initialValue = false;
  const reference = useRef(initialValue);

  useEffect(() => {
    
    function handleResize()
    {
      // console.log('resized to: ', window.innerWidth, 'x', window.innerHeight)

      if(window.innerWidth >= 900) {
        const nav = document.getElementById('navBarContainer');
        if (nav)
        {
          nav.style.display = 'block';
        }
        reference.current = false;
      }
      else {
        if (reference.current === false)
        {
          const nav = document.getElementById('navBarContainer');
          if (nav)
          {
            nav.style.display = 'none';
          }

          reference.current = true;
        }
      }
    }

    window.addEventListener('resize', handleResize)
  })

  const disableNavItemsInMob = () => {

    if (window.innerWidth < 900) {

      const nav = document.getElementById('navBarContainer');

      if (nav && (nav.style.display === '' || nav.style.display === 'none'))
      {
        nav.style.display = 'block';
      }
      else
      {
        nav.style.display = 'none';
      }
    }
  }

  const disableNavItemsInMobFromLogo = () => {
    
    if (window.innerWidth < 900) {

      const nav = document.getElementById('navBarContainer');

      if (!(nav && (nav.style.display === '' || nav.style.display === 'none')))
      {
        nav.style.display = 'none';
      }
    }
  }

  return (
    <NavbarContainer>
      <div className="NavbarContainer_logo">
        <Link to='/'>
          <img src={Blacklogo} alt="" id="logoimg" onClick={disableNavItemsInMobFromLogo}/>
        </Link>
      </div>

      <div className="NavbarMobButton" onClick={disableNavItemsInMob}>
        <img src={NavbarMobButtonImg} alt="Logo" id='Navbar_icon' />
      </div>
      <div className="NavbarContainer_nav" id="navBarContainer">
        <div className="NavbarContainer_items">
          <Link className="NavbarContainer_item" to='/' onClick={disableNavItemsInMob}>Home</Link>
          <Link className="NavbarContainer_item" to='./about' onClick={disableNavItemsInMob}>About</Link>
          <Link className="NavbarContainer_item" to='./services' onClick={disableNavItemsInMob}>Services</Link>
          <Link className="NavbarContainer_item" to='/contact' onClick={disableNavItemsInMob}>Contact</Link>
        </div>
      </div>
    </NavbarContainer>
  )
}

const NavbarContainer = styled.div`
// Global Style for the Nav-Bar
display: flex;
flex: 1 1 0;

/* overflow: hidden; */
height: 50px;
background-color: white;

.NavbarContainer_logo {
  /* background-color: rgba(0,255,0,0.2); */
  width: 50%;

  display: flex;
  /* justify-content: center; */
  align-content: center;
}
  
.NavbarContainer_nav {
  /* background-color: rgba(255,0,0,0.2); */
  width: 50%;

  .NavbarContainer_items {
    /* background-color: red; */
    width: 100%;
    height: 50px;

    padding-right: 60px;
    
    display: flex;
    align-items: center;
    justify-content: right;

    .NavbarContainer_item {
      width: 140px;
      height: 100%;

      color: black;
      color: rgba(0, 0, 0, 0.8);
      font-weight: bold;
      font-family: Montserrat, sans-serif;
      // text-transform: uppercase;
      text-decoration: none;
      font-size:large;

      display: flex;
      justify-content: center;
      align-items: center;
    }

    .NavbarContainer_item:hover {
      background: #662D91;
      color: white;

      height: 85%;

      border-radius: 3px;
      box-shadow: 0 1px 0 white;

      transition: 0.1s ease-in-out;
    }
  }
}

.NavbarMobButton {
  display: none;
}

#Navbar_icon {
  margin-right: 5px;
  width: 35px;
  height: 100%;
  aspect-ratio: 1/1;
  object-fit: contain;
  /* mix-blend-mode: color-burn; */
}

#logoimg {
  margin-left: 60px;
  cursor: pointer;
  width: 50px;
  height: 98%;
}

@media(max-width: 900px)
{
  #logoimg {
    margin-left: 40px;
  }

  .NavbarMobButton
  {
    /* position:absolute; */
    width: 10%;
    height:7%;
    right: 0px;
  }

  .NavbarMobButton {
    display: flex;
    height: 100%;
    width: 50%;
    justify-content: flex-end;
    padding-right: 20px;
    /* background-color: rgba(255,0,255,0.2); */
  }
  
  .NavbarContainer_nav
  {
    display: none;
    z-index: 10;
    margin-top: 50px;
    padding-bottom: 50px;
    overflow: hidden;
    position: absolute;
    height: 100%;
    width: 100%;
    animation: 0.5s ease-in 0s 1 slideInFromLeft;

    .NavbarContainer_items
    {
      left: 0px;
      background-color: white;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      padding: 0;
      
      .NavbarContainer_item
      {
        margin-top: 3%;
        margin-bottom: 3%;
        width: 100%;
        height: 10%;
      }
      .NavbarContainer_item:hover {
        background: #662D91;
        color: white;
        height: 13%;
        border-radius: 3px;
        box-shadow: 0 1px 0 white;
        transition: 0.4s ease-in-out;
     }
    }
  }
}

`

export default Header