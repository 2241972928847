


const SVGIconGivingback = ({dim_width, dim_height}) => {

    if (dim_width == null)
    {
        dim_width = 512;
    }
    if (dim_height == null)
    {
        dim_height = 403;
    }

    return (
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width={dim_width} height={dim_height} viewBox="0 0 512.000000 403.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,403.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M2286 3224 c-38 -8 -99 -30 -135 -49 -37 -18 -147 -56 -246 -84 -99
-29 -232 -70 -295 -92 -179 -64 -203 -69 -392 -81 -140 -10 -198 -9 -282 1
-107 13 -126 11 -126 -14 0 -29 45 -38 205 -43 233 -6 393 9 502 48 82 30 308
101 433 137 111 31 167 51 218 77 133 67 208 69 722 17 272 -27 419 -40 640
-56 82 -6 262 -31 287 -40 19 -7 -5 -63 -35 -83 -23 -15 -59 -17 -267 -19
-132 0 -294 -4 -360 -8 l-120 -6 -18 30 c-33 57 -80 83 -157 89 -117 8 -250
-13 -620 -98 -96 -22 -212 -43 -258 -47 -86 -6 -96 -11 -86 -37 5 -12 21 -16
69 -16 77 0 136 11 470 88 161 38 268 53 380 53 93 1 120 -9 152 -58 14 -20
13 -24 -11 -46 -35 -32 -326 -143 -653 -249 -100 -32 -181 -78 -372 -211 -68
-48 -151 -100 -185 -115 -166 -79 -406 -101 -668 -62 -178 26 -270 50 -481
127 -173 63 -207 69 -207 40 0 -21 17 -31 105 -61 44 -15 121 -43 170 -61 202
-77 527 -132 705 -121 191 12 358 57 468 124 34 21 100 41 237 73 188 43 192
44 360 43 131 -1 235 -10 450 -38 154 -20 290 -38 303 -41 31 -8 94 11 127 37
25 19 38 22 120 19 101 -3 131 6 174 52 20 23 29 25 71 20 83 -10 146 38 165
124 8 41 -6 50 -145 99 -115 40 -449 159 -493 176 -15 6 -25 13 -22 17 4 3
141 8 305 11 282 5 300 6 327 26 43 32 83 131 64 162 -8 14 -191 48 -306 56
-201 16 -482 41 -809 72 -113 11 -161 13 -169 5 -16 -16 -58 -13 -71 4 -17 23
-155 28 -240 9z m935 -425 c107 -39 269 -97 361 -129 218 -77 212 -73 193
-110 -8 -16 -20 -32 -26 -36 -26 -15 -118 -3 -187 25 -78 32 -212 53 -280 44
-37 -5 -44 -3 -48 13 -4 16 -48 34 -200 84 -106 36 -193 69 -191 74 1 4 29 19
61 32 33 13 73 35 90 48 17 14 32 25 32 26 1 0 88 -32 195 -71z m-250 -145
c276 -94 301 -101 408 -115 91 -13 191 -44 191 -60 0 -31 -135 -50 -210 -29
-19 5 -111 25 -205 45 -228 47 -564 116 -595 122 -64 11 -73 14 -64 23 8 8
227 88 245 90 3 0 106 -34 230 -76z m-354 -104 c360 -74 427 -87 538 -110 55
-11 104 -25 109 -30 23 -23 -62 -20 -302 10 -141 18 -295 37 -342 42 -111 13
-390 4 -455 -15 -73 -21 -125 -29 -125 -20 0 8 134 101 180 124 24 12 127 47
142 48 4 1 119 -22 255 -49z"/>
<path d="M3625 1856 c-148 -23 -246 -56 -343 -117 -24 -15 -62 -31 -85 -34
-23 -4 -109 -23 -192 -42 -134 -32 -165 -36 -285 -35 -125 1 -367 25 -681 69
-130 18 -175 13 -217 -22 -26 -22 -35 -23 -103 -18 -91 7 -148 -8 -187 -47
-26 -25 -37 -29 -84 -29 -32 1 -68 -6 -86 -15 -36 -19 -72 -81 -72 -125 0 -33
-22 -22 230 -111 253 -88 420 -152 426 -161 6 -10 -34 -11 -356 -13 -194 -1
-219 -3 -255 -21 -48 -24 -87 -92 -83 -145 3 -30 7 -35 38 -42 67 -15 345 -46
460 -52 106 -6 654 -58 745 -72 39 -5 40 -4 41 23 l1 29 -281 27 c-155 15
-339 32 -411 38 -229 18 -436 39 -486 50 -56 12 -62 29 -29 72 27 34 41 35
405 42 165 3 314 8 332 11 28 5 33 2 47 -24 9 -17 33 -44 53 -59 37 -28 37
-28 187 -27 141 1 186 7 346 44 280 65 368 82 437 87 95 7 113 14 97 40 -22
34 -168 17 -474 -57 -165 -40 -296 -61 -411 -67 l-115 -6 -37 33 c-69 60 -50
80 153 159 69 27 231 84 360 127 231 77 237 80 360 163 69 46 158 107 199 134
83 56 173 98 244 116 76 18 246 41 313 41 73 0 304 -33 425 -61 82 -19 119
-31 344 -112 116 -42 145 -46 145 -18 0 25 5 23 -146 77 -229 82 -267 94 -369
115 -247 49 -432 60 -600 35z m-1485 -227 c91 -11 192 -24 225 -29 235 -35
452 -37 590 -5 39 9 88 19 110 21 l40 6 -23 -19 c-66 -54 -193 -124 -252 -139
l-65 -17 -210 41 c-116 23 -298 60 -405 82 -107 23 -212 44 -233 47 -21 3 -40
10 -43 14 -8 14 38 29 71 23 17 -3 104 -14 195 -25z m-255 -59 c144 -31 492
-102 580 -119 130 -26 170 -37 167 -46 -1 -5 -12 -13 -25 -16 -12 -4 -65 -22
-117 -42 l-95 -34 -250 84 c-288 97 -292 98 -405 112 -90 12 -170 38 -170 57
0 12 38 30 80 37 34 7 73 1 235 -33z m-340 -66 c61 -22 99 -31 246 -58 21 -4
28 0 32 15 7 28 60 23 65 -6 2 -11 7 -24 11 -28 5 -4 93 -36 196 -71 104 -34
190 -67 192 -72 2 -6 -27 -24 -64 -41 -38 -17 -80 -39 -95 -49 -26 -18 -29
-17 -170 35 -79 29 -159 58 -178 66 -19 7 -89 32 -155 55 -183 63 -259 92
-269 101 -14 13 10 58 36 69 38 15 80 11 153 -16z"/>
<path d="M3870 1180 c-137 -10 -204 -23 -292 -58 -40 -16 -176 -60 -303 -97
-126 -37 -262 -83 -302 -102 -101 -48 -197 -66 -305 -58 -85 7 -87 7 -81 -14
10 -37 38 -44 157 -38 112 5 176 21 262 65 43 22 129 50 344 112 41 12 120 37
175 56 200 71 223 75 450 81 116 3 235 1 265 -4 66 -11 80 -8 80 17 0 22 -13
27 -110 40 -84 11 -184 11 -340 0z"/>
</g>
</svg>

    );
};

export default SVGIconGivingback;
