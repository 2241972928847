import styled from "styled-components";
import React from 'react';


const ServicesComponent = ( {ContentSVG, title, describe_text} ) => {
    var svg_icon = 0;
    if (ContentSVG == null)
    {
        svg_icon = <p>not added icon</p>
    }
    else
    {
        svg_icon = ContentSVG();
    }
    
    return (
        <ServicesComponentContainer>
            <div className="container">

                <div className="container_item svg_style">
                    {svg_icon}
                </div>

                <h3 className="container_item">
                    {title}
                </h3>
                <div className="container_item text">
                    {describe_text}
                </div>

            </div>

        </ServicesComponentContainer>
    )
}

const ServicesComponentContainer = styled.div`

width: 100%;
height: 380px;

display: flex;
flex-direction: column;
justify-content: center;

background-color: rgba(0,0,255,0.1);
background-color: white;

border: solid;
border-color: rgba(185, 184, 195, 0.8);
border-width: 1px;
box-sizing: border-box;
transition: all 0.3s ease-in-out !important;
cursor: pointer;
:hover {
    background: rgba(102, 45, 145, 1);

    border-radius: 3px;
    box-shadow: 0 1px 0 white;

    .text {
        color: white !important;
    }

    h3 {
        color: white !important;
    }
    svg path{
        fill: white;;
    }
}

.container {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    /* background-color: rgba(0,0,255,0.1); */
    
    padding-top: 70px;
    padding-bottom: 70px;
    padding-left: 40px;
    padding-right: 40px;

    .container_item {
        flex: 1 1 0;
        /* margin: 1%; */
    }

    .svg_style {
        margin-bottom: 27px;
    }

    h3 {
        font-family: Montserrat, sans-serif;
        color: rgba(51, 51, 51, 1);
        font-size: 24px;
        font-weight: 400;
        line-height: 26px;
        font-style: normal;
        text-align: center;
        margin-bottom: 15px;
    }

    .text {
        /* font-family: Open Sans, sans-serif; */
        font-family: Montserrat, sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.7;
        letter-spacing: 0px;
        color: rgba(125, 119, 137, 1);
        text-align: center;
    }
}


`

export default ServicesComponent
