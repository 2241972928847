import styled from "styled-components";
import Typewritter from "typewriter-effect";
import "../index.css"
import { Link } from "react-router-dom";

// Components
import MyVideoComponent from "../components/myVideoComponent";

const Home = () => {
    return (
        <HomeContainer>
            <div className="HomePage_BlackSceen"></div>
            <div className="HomePage_Video">
                <MyVideoComponent></MyVideoComponent>
            </div>
            <ContentWrapper>
                <h1>Yasmin Ahmed</h1>
                <div className="line"></div>
                <div className="writterContainer">
                    <div>
                        <Typewritter
                            options={{
                                loop: true,
                            }}
                            onInit={(typewriter) => {
                                typewriter
                                    .typeString("Strategic HR Leader")
                                    .pauseFor(2000)
                                    .deleteAll()
                                    .typeString("Consultant")
                                    .pauseFor(2000)
                                    .deleteAll()
                                    .typeString("Coach")
                                    .pauseFor(2000)
                                    .deleteAll()
                                    .typeString("Minority Groups’ Advocate")
                                    .pauseFor(2000)
                                    .deleteAll()                                 
                                    .start();
                            }}
                        />
                    </div>
                </div>
                <div className="getInTouch" id="getTouch">
                    <Link to="./services">Get In Touch</Link>
                </div>
            </ContentWrapper>
        </HomeContainer>
    );
}


const HomeContainer = styled.div`

height: 100%;
width: 100%;

overflow: hidden !important;

.HomePage_BlackSceen {
    background-color: rgba(0,0,0,0.5);
    height: 100%;
    width: 100%;

    position: relative;
    z-index: -3;

}

.HomePage_Video {
    height: 100%;
    width: 100%;

    position: relative;
    z-index: -5;
    top: -100%;
}

`

const ContentWrapper = styled.div`
width: 100%;
height: 100%;

position: relative;
top: -200%;

display: flex;
flex-direction: column;
justify-content: center;
align-items: left;

padding-left: 50px;
flex: 1 1 0;

@media (max-width: 400px) {
    padding-left: 25px;
    #getTouch{
        font-size:22px; 
    }
}

h1 {
    color: white;
    font-size: 3.5rem;
    font-family: 'Montserrat', sans-serif;
}
@media (max-width: 900px)  {
    h1 {
        font-size: 3rem;
    }
}
.line {
    background-color:white;// #262325;
    padding: 0.15rem 4%;
    width: 0%;
    margin: 1rem 0rem;
}

.writterContainer {
    color:white; //#262325;
    font-size: 1.8rem;
    margin: 1rem 0rem;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    font-weight: 700;
    white-space: nowrap;
    h3 {
        color: white;//rgba(51,51,51,0.8);
        font-size: 2.5rem;
        font-weight: 400;
    }
}
@media (max-width: 900px)  {
    .writterContainer {
        display: grid;
        font-size: 1.3rem;
        h3 {
            
            font-size: 1.8rem;
        }
    }
}

.getInTouch {
    /* text-transform: uppercase; */
    background-color: #662D91;//#262325;
    color: #fff;
    width: fit-content;
    padding: 1rem 1.5rem;
    font-size: 1.5rem;
    font-weight: 700;
    margin-top: 2rem;
    cursor: pointer;
    border: solid 1px transparent;
    transition: all 0.5s ease;
    &:hover {
        background-color: #fff;
        border: solid 1px #262325;
        
        a{
            color: black;
        }
    }
    a{
        text-decoration: none;
        color: #fff;
    }
}

`
export default Home;