

const SVGIconCoaching = ({dim_width, dim_height}) => {

  if (dim_width == null)
  {
      dim_width = 185;
  }
  if (dim_height == null)
  {
      dim_height = 194;
  }

  return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width={dim_width} height={dim_height} viewBox="0 0 185.000000 194.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,194.000000) scale(0.100000,-0.100000)"
            fill="#000000" stroke="none">
            <path d="M485 1516 c-65 -28 -114 -122 -99 -189 30 -143 186 -201 294 -111 43
            37 60 75 60 134 0 70 -24 117 -79 151 -51 33 -122 39 -176 15z m140 -101 c50
            -49 24 -133 -46 -150 -62 -15 -123 57 -100 118 22 58 103 76 146 32z"/>
            <path d="M1215 1508 c-60 -35 -87 -89 -82 -165 3 -70 22 -103 77 -140 67 -46
            149 -39 215 18 86 76 81 205 -11 276 -51 39 -142 44 -199 11z m148 -88 c49
            -39 42 -116 -14 -145 -40 -21 -63 -19 -98 11 -89 74 21 206 112 134z"/>
            <path d="M302 1114 c-81 -42 -119 -123 -107 -236 19 -202 118 -388 270 -511
            70 -56 190 -119 264 -138 45 -11 55 -19 72 -52 27 -51 71 -77 134 -77 61 0
            120 35 140 84 12 28 22 35 75 49 189 53 357 192 448 373 65 129 99 308 72 388
            -28 87 -108 141 -199 134 -49 -4 -76 -16 -222 -101 -91 -53 -174 -106 -183
            -116 -10 -11 -20 -38 -23 -60 -5 -38 2 -54 87 -204 51 -89 94 -164 96 -166 2
            -2 22 8 44 23 47 31 53 11 -60 205 -39 66 -70 125 -70 130 0 5 71 50 159 100
            180 103 204 109 253 60 30 -30 31 -32 25 -103 -22 -257 -189 -474 -427 -557
            l-65 -22 -35 35 c-72 72 -168 72 -230 1 l-33 -37 -70 24 c-236 81 -416 321
            -420 563 -2 75 -1 80 26 103 47 40 74 32 248 -67 87 -51 159 -96 159 -101 0
            -5 -33 -67 -73 -136 -110 -190 -104 -167 -57 -198 22 -15 42 -25 44 -23 2 2
            45 76 95 163 99 173 109 210 71 259 -11 14 -95 69 -187 123 -154 89 -173 98
            -228 101 -42 3 -70 -1 -93 -13z m670 -816 c30 -24 31 -56 4 -83 -32 -32 -59
            -32 -85 2 -32 40 -7 93 44 93 12 0 28 -6 37 -12z"/>
            </g>
        </svg>
  );
};

export default SVGIconCoaching;
