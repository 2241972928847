

const SVGIconConsulting = ({dim_width, dim_height}) => {

    if (dim_width == null)
    {
        dim_width = 512;
    }
    if (dim_height == null)
    {
        dim_height = 512;
    }

    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width={dim_width} height={dim_height} viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#000000" stroke="none">
        <path d="M361 5104 c-83 -22 -165 -71 -224 -133 -102 -107 -137 -209 -137
        -397 0 -108 2 -124 21 -148 27 -34 77 -36 106 -3 19 20 22 37 25 157 3 120 6
        141 29 190 37 80 81 125 157 162 l67 33 1155 0 1155 0 55 -26 c79 -37 125 -81
        162 -156 26 -54 32 -77 32 -137 l1 -71 -305 -5 -305 -5 -79 -38 c-89 -42 -174
        -121 -218 -203 -54 -100 -58 -139 -58 -621 l0 -443 -410 0 -411 0 -24 -25
        c-25 -24 -25 -27 -25 -197 l0 -173 -195 195 -195 195 -168 5 c-165 5 -169 6
        -234 38 -76 37 -120 82 -157 162 -26 55 -26 56 -31 376 -5 342 -6 346 -55 358
        -29 7 -80 -15 -89 -39 -3 -9 -6 -153 -6 -319 0 -327 5 -367 56 -468 36 -71
        123 -159 193 -197 83 -44 167 -61 306 -61 l120 0 250 -250 c224 -224 253 -250
        282 -250 20 0 41 8 53 20 19 19 20 33 20 250 l0 230 360 0 360 0 0 -57 c1
        -192 105 -359 276 -440 l79 -38 350 -3 350 -3 256 -254 c218 -218 260 -255
        285 -255 74 1 84 35 84 295 l0 214 543 3 543 3 80 39 c137 68 233 190 263 334
        15 73 15 1171 0 1244 -30 143 -128 268 -264 334 l-80 39 -464 3 c-424 3 -465
        1 -482 -14 -27 -24 -25 -85 2 -110 20 -18 46 -19 468 -24 l446 -5 50 -27 c62
        -32 129 -103 158 -166 22 -47 22 -53 22 -652 0 -599 0 -605 -22 -652 -29 -63
        -96 -134 -158 -166 l-50 -27 -582 -5 -582 -5 -23 -23 c-22 -21 -23 -31 -26
        -194 l-3 -171 -192 191 c-112 111 -202 193 -217 196 -14 3 -182 7 -375 8
        l-350 3 -50 27 c-66 34 -137 111 -161 173 -18 47 -19 82 -19 645 0 563 1 598
        19 645 24 62 95 139 161 173 l50 27 583 5 584 5 19 24 c25 30 24 76 -1 101
        -19 19 -33 20 -235 20 l-215 0 0 63 c0 222 -150 416 -365 472 -86 22 -2311 22
        -2394 -1z"/>
        <path d="M1705 2753 c-155 -21 -311 -134 -380 -277 -46 -96 -59 -181 -52 -340
        7 -184 37 -264 140 -373 l48 -51 -98 -32 c-260 -86 -389 -184 -488 -372 -57
        -108 -75 -196 -75 -374 l0 -153 -381 -3 -381 -3 -19 -24 c-18 -22 -19 -43 -19
        -363 l0 -339 25 -24 24 -25 2511 0 2511 0 24 25 c25 24 25 26 25 215 0 226
        -14 308 -70 425 -95 196 -216 292 -476 381 -60 21 -110 38 -112 39 -1 1 17 21
        39 45 95 100 135 199 145 363 7 120 -7 241 -37 321 -45 123 -163 236 -296 286
        -72 27 -189 36 -266 21 -133 -28 -269 -126 -335 -242 -52 -93 -62 -145 -62
        -328 0 -147 3 -173 23 -231 28 -79 64 -137 118 -191 22 -22 39 -42 37 -43 -2
        -1 -52 -20 -113 -41 -186 -64 -303 -135 -375 -225 l-32 -40 -277 0 -278 0 -6
        162 c-6 187 -19 245 -78 367 -32 64 -60 103 -118 161 -87 88 -161 134 -287
        180 -49 17 -108 39 -131 47 l-42 15 48 55 c55 61 96 137 116 214 22 83 20 326
        -4 411 -65 235 -304 393 -546 361z m212 -183 c76 -35 137 -95 172 -169 25 -53
        26 -63 26 -221 0 -160 -1 -167 -28 -222 -36 -74 -119 -150 -195 -178 -81 -31
        -194 -25 -272 14 -74 38 -144 113 -171 184 -42 113 -36 340 12 434 49 96 152
        171 256 187 72 11 131 2 200 -29z m2339 -611 c57 -18 77 -31 129 -82 90 -90
        105 -137 105 -322 0 -175 -15 -226 -90 -308 -70 -77 -149 -111 -255 -111 -65
        0 -90 4 -137 26 -75 35 -141 98 -175 167 -27 55 -28 60 -28 226 0 195 9 226
        86 310 95 104 226 137 365 94z m-2296 -363 c19 -7 35 -17 35 -23 0 -18 -220
        -392 -227 -385 -3 4 -56 93 -116 197 l-111 190 37 17 c30 14 65 17 192 17 98
        0 168 -5 190 -13z m-415 -329 c82 -144 162 -274 177 -289 30 -32 67 -36 96
        -10 11 9 88 136 172 282 83 146 155 268 158 272 15 14 169 -54 237 -105 155
        -117 215 -269 215 -544 l0 -93 -825 0 -825 0 0 134 c0 73 5 158 10 187 23 122
        115 261 221 334 32 23 191 95 209 95 3 0 72 -118 155 -263z m2943 -351 c202
        -71 253 -99 335 -184 108 -114 147 -231 147 -449 l0 -133 -826 0 -827 0 6 163
        c6 216 35 300 142 415 80 86 133 116 332 187 l178 63 165 1 166 1 182 -64z
        m-1268 -288 c0 -2 -10 -32 -22 -68 -17 -53 -22 -97 -26 -237 l-4 -173 -1509 0
        -1509 0 0 240 0 240 1535 0 c844 0 1535 -1 1535 -2z"/>
        </g>
        </svg>

    );
};

export default SVGIconConsulting;
