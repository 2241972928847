import styled from "styled-components";

import Home from "./pages/home";
import About from "./pages/about";
import Privacy from "./pages/privacy"
import Services from './pages/services'
import Contact from "./pages/contact"
import Imprint from "./pages/imprint";

import Navbar from "./components/header";
import Footer from './components/footer';
import SplashScreen from "./components/loadingScreen";
import useWindowDimensions from "./components/useWindowDimensions";

import { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

function App() {
  const [splashScreenVisible, setSplashScreenVisible] = useState(true);
  const { isMobile } = useWindowDimensions();

  let timeout = 4000;
  let url_str_temp = window.location.href.split("//")[1].split("/");
  if(url_str_temp.length > 1 && url_str_temp[1] !== '')
  {
    timeout = 1;
  }

  useEffect(() => {
    setTimeout(() => {
      setSplashScreenVisible(false);
    }, timeout);
  }, [timeout]);

  return (
    <Wrapper>
      
      {splashScreenVisible&& !isMobile ? <SplashScreen /> :

        <BrowserRouter>
          <div className="BodyContainer">
            <div className="flex_item_header">
              <Navbar></Navbar>
            </div>
            <div className="flex_item_body">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/about" element={<About />} />
                <Route path="/services" element={<Services />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/imprint" element={<Imprint />} />
              </Routes>
            </div>
            <div className="flex_item_footer">
              <Footer></Footer>
            </div>
          </div>
        </BrowserRouter>
      } 
    </Wrapper>
  );
}

const Wrapper = styled.div`
width: 100%;
height: 100%;

.BodyContainer {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  position: fixed;

  flex-direction: column;
 
  .flex_item_header {
    width: 100%;
    height: 50px;
    flex-shrink: 0;
    flex-grow: 0;
  }

  .flex_item_body {
    width: 100%;
    flex-grow: 1;
    flex-shrink: 1;
    
    overflow-x: hidden;
    overflow-y: scroll;

    /* background-color: yellow; */
  }

  .flex_item_footer {
    width: 100%;
    height: 40px;
    flex-shrink: 0;
  }
}
`
export default App;
