import styled from "styled-components";
import React, { useEffect } from "react";

import HomeVideo_mp4 from "../assets/videos/HomePageVideo.mp4";
import HomeVideo_webm from "../assets/videos/HomePageVideo.webm";
import HomeVideo_posterImg from "../assets/videos/HomePageVideo_Poster.jpg";


function MyVideoComponent() {
    useEffect(() => {
        const myVideo = document.getElementById("VideoContainer");

        myVideo.load();

        myVideo.addEventListener("loadeddata", () => {
            console.log("video loaded")
            myVideo.currentTime = 5;
            myVideo.play();
        });

        myVideo.addEventListener("ended", () => {
            console.log("video ended")
            myVideo.currentTime = 5;
            myVideo.play();
        });

        myVideo.addEventListener("timeupdate", () => {
            if ( myVideo.currentTime >= (myVideo.duration - 1) ) {
                console.log("video return")
                myVideo.currentTime = 1;
                myVideo.play();
            }        
        });
    }, []);

    return (
        <VideoWrapper>
            <video 
                className="VideoContainer"
                id="VideoContainer"
                muted playsInline
                poster={HomeVideo_posterImg}
                disablePictureInPicture
            >
                <source src={HomeVideo_mp4} type="video/mp4" />
                <source src={HomeVideo_webm} type="video/webm" />
            </video>
        </VideoWrapper>
    );
}
const VideoWrapper = styled.div`

video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;;
    height: 100vh;
    object-fit: cover;
}

`
export default MyVideoComponent