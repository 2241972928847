import styled from "styled-components";
import ImageComponent from "../components/ImageComponent";

import portfolio_img_2 from '../assets/images/2.jpg';

const About = () => {
  return (
    <AboutContainer>
      <div className="AboutContent">

        <ImageWrapper>
          <ImageComponent ImgSrc={portfolio_img_2}/>
        </ImageWrapper>
        
        <AboutItemsWrapper>
          <div className="AboutItemsWrapper_layout" id="touch">
            <div className="AboutItemsWrapper_title" id="touch">
              <div className="AboutItemsWrapper_title_text">
                About me
              </div>
              <div className="AboutItemsWrapper_title_line">
                <div className="line"></div>
              </div>
            </div>
            
            <div className="AboutItemsWrapper_content">
                <div className="AboutItemsWrapper_contentField">
                    <p>
                      Growing up in both Germany and Egypt, I fell in love with the world of HR at the ripe age of 16 during an internship and never looked back. I've had the pleasure of working in both Germany and Egypt, but my expertise has also taken me to support companies on all continents of the world.
                    </p>
                </div>

                <div className="AboutItemsWrapper_contentField">
                    <p>
                        As a true HR Business Partner at heart, I thrive on being in the thick of the action and understanding the inner workings of a business. My proximity to the business has even led to opportunities for roles outside of HR. But that's the beauty of being an HR pro - you get to know a little bit of everything and industry is never a barrier.
                    </p>
                </div>

                <div className="AboutItemsWrapper_contentField">
                    <p>
                        I have worked with companies of all sizes, from major corporations to fully scaling and building hr teams in small seed-stage start-ups. My professional background includes diverse local and global strategic HR roles within Vodafone, Amazon, Free Now, and most recently as VP People of the German EdTech Start Up simpleclub.
                    </p>
                </div>

                <div className="AboutItemsWrapper_contentField">
                    <p>
                        My current mission and which is why I have decided to go into HR consulting is to change the image HR has had over decades. The image of a policy police that makes employees feel like a burden instead of valuable members of the team. With the right positioning & approach, HR work can fully enable a unique and strong company culture, state-of-the-art initiatives, and engaged & motivated teams that deeply enjoy making the business they work for the best there is.
                    </p>
                </div>
            </div>
          </div>
        </AboutItemsWrapper>

      </div>
    </AboutContainer>
  );
}

const AboutContainer = styled.div`

width: 100%;
height: 100%;

/* background-color: rgba(250, 249, 246, 1); */
background-color: white;
overflow: hidden;

color: rgba(0,0,0,0.9);
font-family: 'Montserrat', sans-serif;

.AboutContent {
  width: 100%;
  height: 100%;
  
  padding-top: 0.3%;
  padding-bottom: 0.3%;
  padding-left: 5%;
  /* padding-right: 0.2%; */

  display: flex;


  @media (max-width: 900px) {
    padding-top: 0.5%;
    padding-bottom: 0.1%;
    padding-left: 0.2%;
    padding-right: 0.2%;
  }

  @media (max-width: 400px) {
    padding-top: 0.5%;
    padding-bottom: 0.2%;
    padding-left: 0.2%;
    padding-right: 0.2%;
  }
}

`

const ImageWrapper = styled.div`
@media (max-width:1200px) {
  display: none;
}

height: 100%;
width: 100%;
flex: 1 1 0;
/* box-shadow: 2px 2px 4px 4px rgba(0, 0, 0, 0.4); */

`

const AboutItemsWrapper = styled.div`
animation: 1s ease-out 0s 1 slideInFromLeft;

overflow: hidden;

height: 100%;
width: 100%;
flex: 2 1 0;

.AboutItemsWrapper_layout {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  padding-left:6%;
  padding-right:6%;

  overflow: hidden;
}

.AboutItemsWrapper_title {
  width: 100%;
  height: 100px;
  
  display: flex;
  justify-content: left;
  align-items: center;

  @media (max-width: 900px) {  
    justify-content: center;
    height: 80px;
  }
  @media (max-width: 800px) {
    justify-content: center;
    height: 60px;
  }
  @media (max-width: 500px) {
    justify-content: center;
    height: 50px;
  }

  .AboutItemsWrapper_title_text {
    font-family: Montserrat, sans-serif;
    text-transform: uppercase;
    letter-spacing: 7px;
    font-size: 20px;
    font-feature-settings: normal;
    font-weight: 700;
    color: #1c1259;

    @media (max-width: 900px) {
      font-size: 30px;
    }
    @media (max-width: 400px) {
      font-size: 26px;
    }

  }
  .AboutItemsWrapper_title_line {
    flex: 1 1 0;

    width: 100%;
    height: 23.2px;
    display: flex;
    justify-content: center;
    align-items: center;

    .line {
      width: 100%;
      flex: 1 1 0;
      height: 100%;
      height: 1.5px;
      margin-left: 25px;
      margin-right: 25px;
      background-color: #1c1259;
    }

    @media (max-width: 900px) {
      display: none;
    }
  }

}

.AboutItemsWrapper_content {
  width: 100%;
  flex: 1 1 0;

  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;
  overflow: scroll;

  .AboutItemsWrapper_contentField {
    margin-top: 15px;
    margin-bottom: 15px;
    width: 100%;

    p {
        color: rgba(0,0,0,0.8);
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 1px;
        font-weight: 500;
    }

    display: flex;
    justify-content: center;
    align-items: center;
  }
}

`

export default About;